<template>
  <div>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>新增</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <a-form :form="form" layout="inline">
      <section class="card__container">
        <a-form-item label="买方机构" class="width-70">
          <a-select
            show-search
            class="select"
            placeholder="选择渠道终端"
            :filter-option="filterOption"
            @change="onChange"
          >
            <a-select-option v-for="item in options" :key="item.distributorId">
              {{ item.corporationName }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="订单类型" class="width-50">
          即时订单
        </a-form-item>
        <a-form-item label="订单类别" class="width-50">
          采购订单
        </a-form-item>
        <a-form-item label="收货地址" class="width-70">
          {{ addressText || "--" }}
          <a-icon
            type="edit"
            class="icon-edit"
            @click="openAddressModal"
            v-if="distributorId"
          />
        </a-form-item>
        <a-form-item label="订单备注" class="width-70">
          <a-textarea :rows="3" class="textarea" v-model="memo" />
        </a-form-item>
      </section>
      <section class="card__container">
        <a-form-item class="width-100">
          <sku-select :distributorId="distributorId" ref="skuSelect" />
        </a-form-item>
      </section>
    </a-form>
    <div class="footer">
      <a-button @click="onBack">返回列表</a-button>
      <a-button type="primary" @click="onSubmit" :loading="submitLoading">
        提交订单
      </a-button>
    </div>

    <a-modal title="选择收货地址" :visible="visible" @cancel="visible = false">
      <div v-if="addressList.length > 0">
        <a-radio-group v-model="defaultAddress">
          <a-radio
            v-for="item in addressList"
            :key="item.id"
            :value="item.addressItem"
            class="radio-item"
          >
            {{ item.addressItem }}
            <a-icon type="edit" class="icon-edit" @click="editAddress(item)" />
          </a-radio>
        </a-radio-group>
      </div>
      <a-empty v-else description="暂无地址" />
      <template slot="footer">
        <a-button @click="onAddAddress">
          添加地址
        </a-button>
        <a-button type="primary" @click="onConfirmAddress">
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import SkuSelect from "./components/SkuSelect.vue";
import { organizationInfoList } from "@/services/ValueBag";
import { fetchReceiverAddressInfo, placeOrder } from "@/services/OrderService";
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import { pageDetailMixins } from "@/components/pageDetailMixin";

export default {
  components: { BreadcrumbItem, Breadcrumb, SkuSelect },
  mixins: [pageDetailMixins],
  data() {
    return {
      form: this.$form.createForm(this, { name: "agentOrderForm" }),
      options: [],
      addressText: "",
      visible: false,
      addressList: [],
      defaultAddress: "",
      distributorId: "",
      memo: "",
      submitLoading: false
    };
  },
  mounted() {
    this.loadEndConsumerMarketList();
  },
  activated() {
    if (this.$store.state.addressInfo) {
      let index = this.addressList.findIndex(
        ele => ele.id === this.$store.state.addressInfo.id
      );
      if (index <= -1) {
        this.addressList.push(this.$store.state.addressInfo);
      } else {
        this.addressList[index] = this.$store.state.addressInfo;
        this.addressList = [...this.addressList];
      }
      this.addressText = this.$store.state.addressInfo.addressItem;
      this.defaultAddress = this.addressText;
      this.$store.state.addressInfo = null;
    }
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    onSubmit() {
      if (!this.distributorId) {
        this.$message.info("请选择买方机构");
        return;
      }
      if (!this.addressText) {
        this.$message.info("请填写收货地址");
        return;
      }
      if (this.$refs.skuSelect.editDisabled) {
        this.$message.info("请先完成编辑");
        return;
      }
      let skuList = this.$refs.skuSelect.skuList;
      let list = [];
      if (skuList.length > 0) {
        skuList.forEach(ele => {
          list.push({
            skuId: ele.skuId,
            purchaseUnitPrice: ele.purchaseUnitPrice,
            purchaseQuantity: ele.purchaseQuantity,
            producerCorporationId: ele.producerCorporationId,
            productType: ele.productType
          });
        });
      } else {
        this.$message.info("请先选择下单商品");
      }
      let address = this.addressList.find(
        ele => ele.addressItem === this.addressText
      );
      const params = {
        orderType: "INSTANT",
        purchaserDistributorId: this.distributorId,
        orderSkuList: list,
        memo: this.memo,
        orderPlaceFlag: true,
        consigneeName: address.name,
        consigneeTel: address.tel,
        consigneeProvinceCode: address.provinceCode,
        consigneeCityCode: address.cityCode,
        consigneeCountyCode: address.countyCode,
        consigneeDetailAddress: address.detailAddress
      };
      this.submitLoading = true;
      this.submitOrder(params);
    },
    // 下单
    submitOrder(params) {
      placeOrder(params)
        .then(resp => {
          this.submitLoading = false;
          if (resp.data.code === "SUCCESS") {
            this.$message.success("提交成功");
            this.$store.state.hasChange = true;
            this.$router.push({ name: "OperationAgentOrderList" });
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    // 加载渠道终端
    loadEndConsumerMarketList() {
      organizationInfoList("endConsumerMarket").then(resp => {
        this.options = resp.data.data || [];
      });
    },
    // 切换渠道终端
    onChange(value) {
      this.loadReceiverAddressInfo(value);
    },
    // 加载收货人信息和地址
    loadReceiverAddressInfo(distributorId) {
      this.distributorId = distributorId;
      let item = this.options.find(ele => ele.distributorId === distributorId);
      fetchReceiverAddressInfo(item.corporationId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.addressList = resp.data.data || [];
          if (this.addressList.length > 0) {
            this.addressList.forEach(e => {
              e.addressItem = `${e.address}，${e.name}，${e.tel}`;
              if (e.isDefault) {
                this.defaultAddress = e.addressItem;
                this.addressText = this.defaultAddress;
              }
            });
          } else {
            this.defaultAddress = "";
            this.addressText = "";
          }
        }
      });
    },
    // 打开地址弹窗
    openAddressModal() {
      this.visible = true;
    },
    // 添加地址
    onAddAddress() {
      this.visible = false;
      this.$router.push({
        name: "EditReceiverAddress",
        params: { type: "add" }
      });
    },
    // 确认地址
    onConfirmAddress() {
      if (!this.defaultAddress) {
        this.$message.info("请选择收货地址");
      } else {
        this.addressText = this.defaultAddress;
        this.visible = false;
      }
    },
    // 编辑地址
    editAddress(address) {
      this.visible = false;
      this.$store.state.addressInfo = address;
      this.$router.push({
        name: "EditReceiverAddress",
        params: { type: "edit" }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    }
  }
};
</script>

<style scoped>
.width-100 {
  width: 100%;
  margin-bottom: 20px;
}
.width-100 ::v-deep .ant-form-item-control-wrapper {
  width: 100%;
}
.width-50 {
  width: calc(50% - 16px);
  margin-bottom: 20px;
}
.width-70 {
  width: 100%;
  margin-bottom: 20px;
}
::v-deep .ant-form-item-label {
  margin-right: 20px;
}
.select {
  width: 300px;
}
.textarea {
  width: 600px;
}
.footer {
  height: 72px;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 -1px 6px 0 rgba(0, 0, 0, 0.12);
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 33px;
  z-index: 10;
}

.footer .ant-btn {
  margin-left: 16px;
}

.icon-edit {
  margin-left: 10px;
  cursor: pointer;
}

.radio-item {
  margin-bottom: 10px;
  display: block;
}

.card__container:last-child {
  margin-bottom: 100px;
}
</style>
