<template>
  <div class="cell">
    <template v-if="!isEdit">
      {{ value }}
      <a-icon type="edit" class="button" @click="onEdit" />
    </template>
    <template v-else>
      <slot></slot>
      <a-icon type="check" class="button" @click="onConfirm" />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    defaultEdit: {
      type: Boolean,
      default: false
    },
    requiredRule: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEdit: !!this.defaultEdit
    };
  },
  methods: {
    onEdit() {
      if (this.disabled) {
        if (this.requiredRule && this.requiredRule.message) {
          this.$message.info("请先确认数量");
        } else {
          this.$message.info("请先确认商品编号");
        }
        return;
      }
      this.isEdit = true;
      this.$emit("update:disabled", true);
    },
    onConfirm() {
      console.log(this.requiredRule);
      if (this.requiredRule.required && !this.requiredRule.value) {
        this.$message.error(this.requiredRule.message);
        return;
      }
      this.isEdit = false;
      this.$emit("confirm");
      this.$emit("update:disabled", false);
    }
  }
};
</script>

<style scoped>
.cell {
  display: flex;
  align-items: center;
}
.button {
  color: #3569fc;
  margin-left: 10px;
}
</style>
