<template>
  <div>
    <div class="label">
      商品信息
      <a-icon
        type="plus-square"
        class="add-button"
        @click="addRow"
        v-if="distributorId"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="skuList"
      rowKey="skuCode"
      :pagination="false"
    >
      <change-to-edit
        slot="skuCode"
        slot-scope="text, record, index"
        :value="text"
        :default-edit="true"
        :required-rule="{
          required: true,
          value: record.skuCode,
          message: '请选择商品编号'
        }"
        :disabled.sync="editDisabled"
        @confirm="onIdConfirm(index)"
      >
        <a-select
          :default-value="record.skuCode"
          show-search
          :filter-option="filterOption"
          @change="value => onChangeSkuCode(value, record)"
        >
          <a-select-option
            v-for="item in skuOptions"
            :key="item.skuCode"
            :disabled="item.disabled"
          >
            {{ item.skuCode }}
          </a-select-option>
        </a-select>
      </change-to-edit>
      <change-to-edit
        slot="purchaseQuantity"
        slot-scope="text, record, index"
        :value="text"
        :disabled.sync="editDisabled"
        @confirm="onQuantityConfirm(record)"
      >
        <a-input-number
          :default-value="text"
          :ref="`quantityInput${index}`"
          :min="1"
          :formatter="formatInteger"
          :parser="formatInteger"
          @change="value => onQuantityChange(value, record)"
        />
      </change-to-edit>
      <a-popconfirm
        slot="operation"
        slot-scope="text, record, index"
        title="确定要删除吗？"
        ok-text="确定"
        cancel-text="取消"
        @confirm="onDeleteSku(index)"
      >
        <span class="text-button">
          删除
        </span>
      </a-popconfirm>
      <template slot="footer">
        <div style="width: 15%" class="table-box">
          合计
        </div>
        <div style="width: 30%" class="table-box"></div>
        <div style="width: 15%" class="table-box"></div>
        <div style="width: 15%" class="table-box">
          {{ totalNum || "-" }}
        </div>
        <div style="width: 15%;" class="table-box">
          {{ totalPrice || "-" }}
        </div>
        <div style="width: 10%;" class="table-box"></div>
      </template>
    </a-table>
  </div>
</template>

<script>
import ChangeToEdit from "./ChangeToEdit.vue";
import { getSkuList } from "@/services/OrderManageService";
import {
  batchFetchProductPrice,
  fetchProductPrice
} from "@/services/OrderService";

export default {
  components: {
    ChangeToEdit
  },
  props: {
    distributorId: {
      type: String,
      default: ""
    }
  },
  watch: {
    distributorId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.reacquirePrice();
      }
    }
  },
  data() {
    return {
      columns: [
        {
          title: "商品编号",
          dataIndex: "skuCode",
          scopedSlots: { customRender: "skuCode" },
          width: "15%"
        },
        {
          title: "商品名称",
          dataIndex: "skuName",
          width: "30%"
        },
        {
          title: "进货单价（元）",
          dataIndex: "purchaseUnitPrice",
          width: "15%"
        },
        {
          title: "采购数量",
          dataIndex: "purchaseQuantity",
          scopedSlots: { customRender: "purchaseQuantity" },
          width: "15%"
        },
        {
          title: "进货总价（元）",
          dataIndex: "skuTotalPrice",
          width: "15%"
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: "10%"
        }
      ],
      skuList: [],
      skuOptions: [],
      editDisabled: false,
      totalNum: 0,
      totalPrice: 0
    };
  },
  async created() {
    const { data = {} } = await getSkuList();
    this.skuOptions = data.data.records || [];
    if (this.skuOptions.length > 0) {
      this.skuOptions.forEach(ele => {
        ele.disabled = false;
      });
    }
  },
  methods: {
    onDeleteSku(index) {
      this.skuList.splice(index, 1);
      this.editDisabled = false;
      this.$message.success("删除成功");
      this.dealOptionDisable();
    },
    addRow() {
      if (this.editDisabled) {
        this.$message.info("请先完成编辑");
        return;
      }
      this.dealOptionDisable();
      this.skuList.push({
        skuCode: "",
        skuName: "--",
        purchaseUnitPrice: "--",
        purchaseQuantity: "1",
        skuTotalPrice: "--",
        skuId: "",
        producerCorporationId: "",
        productType: ""
      });
      this.editDisabled = true;
    },
    onQuantityChange(value, record) {
      record.purchaseQuantity = String(value);
    },
    onQuantityConfirm(record) {
      if (record.purchaseUnitPrice && !isNaN(record.purchaseUnitPrice)) {
        record.skuTotalPrice = (
          record.purchaseUnitPrice * Number(record.purchaseQuantity)
        ).toFixed(2);
        this.calculateNumAndPrice();
      }
    },
    onIdConfirm() {},
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 切换商品编号
    async onChangeSkuCode(value, record) {
      let item = this.skuOptions.find(ele => ele.skuCode === value);
      const { data = {} } = await fetchProductPrice(
        item.skuId,
        this.distributorId
      );
      record.purchaseUnitPrice = data.data;
      record.skuId = item.skuId;
      record.skuName = item.skuName;
      record.skuCode = value;
      record.skuTotalPrice = (
        record.purchaseUnitPrice * Number(record.purchaseQuantity)
      ).toFixed(2);
      record.producerCorporationId = item.factoryId;
      record.productType = item.productType;
      this.calculateNumAndPrice();
    },
    // 重新获取价格
    reacquirePrice() {
      let skuIds = [];
      if (this.skuList.length > 0) {
        let list = [...this.skuList];
        list = list.filter(ele => ele.skuCode);
        if (list.length > 0) {
          list.forEach(e => {
            skuIds.push(e.skuId);
          });
          batchFetchProductPrice(this.distributorId, skuIds).then(resp => {
            if (resp.data.code === "SUCCESS") {
              const data = resp.data.data;
              if (data) {
                for (const dataKey in data) {
                  let index = this.skuList.findIndex(
                    ele => ele.skuId === dataKey
                  );
                  if (index > -1) {
                    this.skuList[index].purchaseUnitPrice = data[dataKey];
                    this.skuList[index].skuTotalPrice = (
                      this.skuList[index].purchaseUnitPrice *
                      Number(this.skuList[index].purchaseQuantity)
                    ).toFixed(2);
                  }
                }
              }
            }
          });
        }
      }
    },
    // 处理option禁用
    dealOptionDisable() {
      if (this.skuOptions.length > 0) {
        this.skuOptions.forEach(item => {
          item.disabled = false;
        });
      }
      if (this.skuList.length > 0) {
        this.skuList.forEach(ele => {
          if (ele.skuCode) {
            let index = this.skuOptions.findIndex(
              e => e.skuCode === ele.skuCode
            );
            if (index > -1) {
              this.skuOptions[index].disabled = true;
              this.skuOptions = [...this.skuOptions];
            }
          }
        });
      }
    },
    formatInteger(value) {
      if (typeof value === "string") {
        return !isNaN(Number(value)) ? value.replace(/\./g, "") : 0;
      } else if (typeof value === "number") {
        return !isNaN(value) ? String(value).replace(/\./g, "") : 0;
      }
      return 0;
    },
    // 计算数量和金额
    calculateNumAndPrice() {
      this.totalNum = 0;
      this.totalPrice = 0;
      let list = this.skuList.filter(ele => ele.skuCode);
      if (list.length > 0) {
        list.forEach(e => {
          this.totalNum = this.totalNum + Number(e.purchaseQuantity);
          this.totalPrice = this.totalPrice + Number(e.skuTotalPrice);
        });
      }
      this.totalPrice = this.totalPrice.toFixed(2);
    }
  }
};
</script>

<style scoped>
.label {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.add-button {
  color: #101010;
  font-size: 22px;
  margin-left: 10px;
}

.text-button {
  color: #3569fc;
  cursor: pointer;
}

/deep/ .ant-table-footer {
  display: flex;
  padding: 0;
}

.table-box {
  box-sizing: border-box;
  padding: 16px 16px;
}
</style>
